<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar
          color="primary"
          dark
        >
          <span>{{ $route.params.customer }} Customer Details</span>
          <v-spacer></v-spacer>
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-toolbar>
        <v-alert
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in custDetPostErrors"
          :key="index"
        >
          <span class="text-uppercase ">{{ item.first() }}</span>
        </v-alert>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-autocomplete
          label="Product Category"
          :items="prdCatList"
          :loading="prdCatGetting"
          v-model="productCategoryId"
          item-value="id"
          item-text="name"
        >
          <!-- <template v-slot:selection="{ item, index }">
            <span class="primary--text">{{ index }}</span><span class="blue-grey--text mx-1 text-caption">{{ item.name }}</span>
          </template>
          <template v-slot:item="{ item, index }">
            <span class="primary--text">{{ index }}</span><span class="blue-grey--text mx-1 text-caption">{{ item.name }}</span>
          </template> -->
        </v-autocomplete>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Capacity"
          type="number"
          v-model="capacity"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Capacity Unit"
          v-model="capacityUnit"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="EX Supplier"
          v-model="exSupplier"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Potential"
          v-model="potential"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-checkbox
          v-model="confirmed"
          label="Confirmed"
        ></v-checkbox>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Date Start"
          v-model="dateStart"
          type="date"
        ></v-text-field>
      </v-col>
      <v-col
        lg="1"
        cols="12"
      >
        <v-btn
          color="primary"
          :loading="custDetPosting"
          block
          dark
          @click="storeCustDet"
        >Save</v-btn>
      </v-col>
      <v-col
        lg="1"
        cols="12"
      >
        <v-btn
          color="red"
          block
          dark
          @click="$router.push('/customers')"
        >Cancel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import custDetRepository from '@/repositories/customer-detail.repository'
import prdCatRepository from '@/repositories/product-category.repository'
import { postVars, errorHandler, getVars } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'

const storeDelay = searchDelay()
const getPrdCatDelay = searchDelay()

const inputVars = () => ({
  productCategoryId: null,
  capacity: null,
  capacityUnit: null,
  exSupplier: null,
  potential: null,
  confirmed: false,
  dateStart: null,
})

export default {
  name: 'CreateCustomerDetail',
  created () {
    this.getPrdCat()
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...postVars('cust-det'),
      ...getVars('prd-cat'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
    }),
  },
  methods: {
    getPrdCat () {
      if (this.prdCatGetting) return
      this.prdCatGetting = true
      this.prdCatGetErrors = []
      getPrdCatDelay(() => {
        prdCatRepository.index()
          .then(({ data }) => { this.prdCatList = data })
          .catch(e => { this.prdCatGetErrors = errorHandler(e) })
          .then(() => { this.prdCatGetting = false })
      })
    },
    websocketEvents () {
      this.echo.private('database.event')
        .listen('DBStoreEvent', this.execPayloadStoreEvent)
    },
    execPayloadStoreEvent ({ model, data }) {
      if (!model) return
      if (model === 'ProductCategory' && !this.$objectEmpty(data)) {
        this.prdCatList.objectInsertion(data, 'id', 'id')
      }
    },
    storeCustDet () {
      if (this.custDetPosting) return
      this.custDetPosting = true
      this.custDetPostErrors = []
      storeDelay(() => {
        const data = this.getInputData()
        custDetRepository.store(data)
          .then(() => { this.$router.push({ path: '/customers' }) })
          .catch(e => { this.custDetPostErrors = errorHandler(e) })
          .then(() => { this.custDetPosting = false })
      })
    },
    getInputData () {
      return {
        customer_id: this.$route.params.customer,
        product_category_id: this.productCategoryId,
        capacity: this.capacity,
        capacity_unit: this.capacityUnit,
        ex_supplier: this.exSupplier,
        potential: this.potential,
        confirmed: this.confirmed,
        date_start: this.dateStart,
      }
    },
  },
}
</script>
