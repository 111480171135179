import repository from './repository'

const resource = 'customer-details'

export default {
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
}
