import repository from './repository'

const resource = 'product-categories'

export default {
  index () {
    return repository.get(resource)
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
}
